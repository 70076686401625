// ========================================
// btn.scss
// ========================================

// ---------------------------------
// btn-cmn
// ---------------------------------

.btn-cmn { @include btn-cmn; }

// ---------------------------------
// btn-check
// ---------------------------------

.btn-check {
  @include btn-cmn;

  background-color: colors("black-400");
  color: $white;
  width: 80%;
  padding: 1rem 0;

  &:hover {
    background-color: colors("black-900");
    color: $white;
  }
}

// ---------------------------------
// btn-sitelink
// ---------------------------------

.btn-sitelink {
  @include btn-cmn;

  background-color: colors("black-400");
  color: $white;
  width: 80%;
  padding: 2rem 0;

  &:hover {
    background-color: $white;
    color: colors("black-800");
  }
}

// ---------------------------------
// btn-close
// ---------------------------------

.btn-close {
  @include btn-cmn;

  background-color: colors("bright-green");
  color: $white;
}

// ---------------------------------
// btn-yellowtab
// ---------------------------------

.btn-yellowtab {
  @include btn-cmn;

  border: 1px solid $grey-500;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px 10px;

  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
    padding: 15px 10px;
  }

  &--status_active {
    color: $black;
    background-color: $bright-yellow-900;
  }
}
