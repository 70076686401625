// ========================================
// modal.scss
// ========================================

// ---------------------------------
// service-info-modal
// ---------------------------------

.service-info-modal {
  max-width: 80%;

  &__inner {
    height: 800px;
  }

  &__imgs {
    margin: 8rem;
    text-align: center;

    &-img {
      max-height: 600px;
      text-align: center;
    }
  }

  &__footer {
    background-color: $grey-100;
    color: $white;

    &-btn {
      font-size: 1.8rem;
      background-color: $dark-green-100;
      color: $white;

      &:hover {
        color: $white;
        background-color: $dark-green-200;
      }
    }
  }
}
