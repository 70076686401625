// ========================================
// _breadcrumb.scss
// ========================================

// ---------------------------------
// .site-breadcrumb
// ---------------------------------

.site-breadcrumb {
  background-color: $gray-200;
  text-align: center;
}

.site-breadcrumb-items {
  letter-spacing: -0.2em;
  list-style: none;
  margin: 0;
}

.site-breadcrumb-item {
  display: inline-block;
  font-size: 1.2rem;
  letter-spacing: normal;
  padding: 0;
  margin: 10px;

  &::after {
    content: '>';
    padding-left: 20px;
  }

  &:last-child::after {
    content: none;
  }

  &__link {
    color: $black;
    text-decoration: none;

    &:hover {
      color: $blue;
    }

    &--type_current {
      cursor: default;

      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
  }
}

// ---------------------------------
// Main container 全ページ反映後削除
// ---------------------------------

.Breadcrumb {
  text-align: center;
  background-color: $gray-200;

  &__Wrapper {
    letter-spacing: -0.2em;
    list-style: none;
    margin: 0;
  }

  &__item {
    display: inline-block;
    letter-spacing: normal;
    margin: 10px;
    padding: 0;
    font-size: 1.2rem;

    &::after {
      content: '>';
      padding-left: 20px;
    }

    &:last-child::after {
      content: none;
    }
  }

  &__link {
    text-decoration: none;
    color: $black;

    &:hover {
      color: $blue;
    }

    &--current {
      text-decoration: none;
      color: $black;
      cursor: default;

      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
  }
}
