// ========================================
// footer
// ========================================

// ---------------------------------
// site-footer
// ---------------------------------

.site-footer {
  background-color: $black;
  overflow: hidden;
  z-index: $zindex-fixed;
  height: 150px;

  &__inner {
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 5rem;
  }

  &__txt {
    color: $white;
    font-style: italic;
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.05rem;
    text-shadow: $gray-200 1px 0 1px;
  }

  &__copy {
    color: $white;
    font-size: 1.2rem;
  }
}
