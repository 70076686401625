// ---------------------------------
//  access__dgm-box
// ---------------------------------
.CmnBack__MordalWrapper {
  max-width: 80%;
}

.MordalWrapper__MordalContents {
  height: 800px;
}

.MordalContents__ImageWrapper {
  margin: 8rem;
  text-align: center;
}

.MordalContents__image {
  max-height: 600px;
  text-align: center;
}

.MordalContents__MordalFooter {
  background-color: $grey-100;
  color: $white;
}

.MordalContents__btn {
  font-size: 1.8rem;
  background-color: $dark-green-100;
  color: $white;

  &:hover {
    color: $white;
    background-color: $dark-green-200;
  }
}

.ServiceInfo__ZoomImage {
  position: relative;
}

.ServiceInfo__icon--zoom {
  position: absolute;
  cursor: pointer;
  font-size: 3rem;
  color: $dark-green-100;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  transition: color 0.3s ease;

  &:hover {
    color: $dark-green-200;
  }
}
