// ========================================
// header
// ========================================

// ---------------------------------
// site-header
// ---------------------------------

.site-header {
  background-color: $white;
  box-shadow: 0 0 1px rgba(1, 1, 1, 0.8);
  position: fixed;
  z-index: $zindex-fixed;
  width: 100%;

  &__inner {
    display: flex;
    height: 80px;

    @include media-breakpoint-down(md) {
      display: block;
      height: 70px;
    }
  }
}

.site-header-index {
  line-height: 80px;
  height: 80px;
  padding: 0 1.6rem;

  @include media-breakpoint-down(md) {
    line-height: 70px;
    height: 70px;
  }

  &__logo {
    vertical-align: middle;
    width: 120px;
    height: auto;
  }
}

// ---------------------------------
// site-header-toggler
// ---------------------------------

.site-header-toggler {
  display: none;
  float: right;
  height: 70px;
  margin-right: 2rem;

  @include media-breakpoint-down(md) {
    display: block;
  }

  &__inner {
    display: flex;
    align-items: center;
    height: 70px;
  }

  &__icon {
    cursor: pointer;
    font-size: 4rem;
  }
}

// ---------------------------------
// site-header-nav
// ---------------------------------

.site-header-nav {
  clear: both;
  color: $black;
  font-weight: bold;

  @include media-breakpoint-down(md) {
    font-size: 1.4rem;
  }
}

.site-header-nav-lists {
  list-style: none;
}

.site-header-nav-list {
  $_root: &;
  $_root-hover: $_root + ":hover";

  background: $white;
  float: left;
  line-height: 80px;
  list-style: none;
  position: relative;
  text-align: center;
  width: 200px;

  &::before,
  &::after {
    content: "";
    display: table;
  }

  @include media-breakpoint-down(md) {
    border-bottom: 1px solid $black;
    line-height: 60px;
    width: 100%;

    &:first-child {
      border-top: 1px solid $black;
    }
  }

  &__link {
    display: block;
    color: $black;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: $bright-green-900;
      transition: 0.6s;
      text-decoration: none;
    }
  }

  &__sublists {
    font-size: 1.4rem;
    list-style: none;
    padding: 0;
    margin: 0;

    @include media-breakpoint-down(md) {
      transition: all 0.2s ease;
    }

    #{$_root-hover} & {
      display: block !important;
    }
  }

  &__sublist {
    background-color: $bright-green-100;

    &:hover {
      background-color: $bright-green-400;
    }

    &-link {
      color: $black;
      display: block;
      line-height: 60px;
      text-decoration: none;
      margin: 0;
    }
  }
}
