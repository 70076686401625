// ========================================
// heading.scss
// ========================================

// ---------------------------------
// lv2-heading
// ---------------------------------

.lv2-heading {
  background-color: $bright-green-900;
  color: $white;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  margin-bottom: 30px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }

  &__num {
    font-size: 3rem;
    font-weight: bold;
    margin: 0 16px 0 0;

    @include media-breakpoint-down(sm) {
      font-size: 4rem;
    }
  }

  &__ttl {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;

    @include media-breakpoint-down(sm) {
      font-size: 2.2rem;
    }
  }
}
