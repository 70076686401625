// ========================================
// serivice
// ========================================

// ---------------------------------
// service-heading
// ---------------------------------

.Sec-service-heading {
  margin: 40px 0;
}

.service-heading-txts {
  &__txt {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.04rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
    }

    &--size_md {
      font-size: 3.6rem;

      @include media-breakpoint-down(sm) {
        font-size: 2.2rem;
      }
    }

    &--size_lg {
      font-weight: 900;
      font-size: 5rem;

      @include media-breakpoint-down(sm) {
        font-size: 3.4rem;
      }
    }

    &--type_attention {
      color: $bright-green-900;
    }
  }
}

// ---------------------------------
// service-info
// ---------------------------------

.service-info {
  margin-bottom: 5rem;
}

.service-info-detail {
  &__ttl {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: 900;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
      font-size: 2rem;
    }

    &--type_green {
      color: $bright-green-900;
    }
  }

  &__lead {
    letter-spacing: 0.1rem;

    &-attention {
      color: $pink;
      font-weight: bold;
    }

    &--type_green {
      font-size: 1.8rem;
      font-weight: bold;
      color: $bright-green-900;
    }

    &--type_pink {
      color: $bright-pink-900;
      font-weight: bold;
    }
  }

  &__message {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 900;
    color: $bright-red-900;
  }

  &__img {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    &-wrapper {
      margin: 3rem 0;
      text-align: center;
    }
  }

  &__bottom {
    font-weight: bold;
    text-align: right;
  }
}

.service-info-detail-lists {
  margin-bottom: 4rem;

  &__list {
    list-style: none;
    background-color: $grey-100;
    margin: 1rem 0;
    font-weight: bold;
  }
}

.service-info-detail-howto {
  &__wrapper {
    margin-bottom: 3rem;
  }

  &__img {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__lead {
    font-weight: bold;
  }
}

.service-info-detail-movie {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.service-info-detail-link {
  float: none;

  &__txt {
    margin-bottom: 1.6rem;
    font-weight: bold;
    font-size: 1.8rem;
  }
}

.service-info-detail-overseas {
  display: flex;
  margin-bottom: 5rem;

  @include media-breakpoint-down(sm) {
    display: inline-block;
  }

  &__heading {
    width: 60%;
    margin-right: 4rem;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 3rem;
    }
  }

  &__imgs {
    width: 40%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.service-info-detail-comment {
  display: flex;
  margin: 4rem 0;

  @include media-breakpoint-down(sm) {
    display: inline-block;
  }

  &__leads {
    position: relative;
    display: inline-block;
    width: 70%;
    padding: 3rem;
    color: $grey-600;
    font-size: 1.6rem;
    font-weight: bold;
    background: $right-blue-900;
    border-radius: 15px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: -30px;
      margin-top: -14px;
      border: 14px solid transparent;
      border-left: 16px solid $right-blue-900;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -15px;
    }
  }

  &__imgs {
    width: 30%;
    margin-left: 3rem;

    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
    }
  }
}

.service-info-detail-merits {
  background-color: $bright-yellow-300;
}

.service-info-detail-merit {
  &__ttl {
    color: $bright-green-900;
    font-size: 2.4rem;
    font-weight: 900;
    margin-bottom: 2rem;

    @include media-breakpoint-down(sm) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  &__lists {
    &-list {
      margin: 0 0 1rem 4rem;
    }
  }
}

.service-info-detail-support {
  height: 230px;
  text-align: center;
  color: $body-color;
  background-color: $white;
  border: 1px solid $grey-100;

  &__img {
    margin: 3rem;
    text-align: center;
  }
}

.service-info-detail-aibiflow {
  margin-bottom: 3rem;

  @include media-breakpoint-down(sm) {
    text-align: center;
    margin-bottom: 1rem;
  }

  &__ttl {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
  }

  &__heading {
    &-txt {
      padding: 0.5rem 0;
      font-size: 1.6rem;
      color: $white;
      text-align: center;
      background-color: $bright-red-900;
    }
  }

  &__lists {
    background-color: $bright-red-100;

    &-list {
      padding: 0 2rem;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }

  &__img {
    margin: 10rem 2rem;

    @include media-breakpoint-down(sm) {
      margin: 0;
      text-align: center;
      transform: rotate(90deg);
    }
  }
}

// ---------------------------------
// cmn-mv  ← 別タスクにて移行修正予定 by nakajima
// ---------------------------------

.cmn-mv {
  width: 100%;
  clear: both;
  display: inline-block;
  margin-top: 8rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @include media-breakpoint-down(md) {
    margin-top: 1rem;
  }

  &__ttl {
    position: absolute;
    width: 100%;
    margin-top: 16rem;
    z-index: $zindex-mvlogo;
    text-align: center;
    text-shadow: $black 1px 2px 6px;
    color: $white;
    font-size: 3rem;
    font-weight: bold;

    @include media-breakpoint-down(md) {
      margin-top: 11rem;
      font-size: 2rem;
    }
  }

  &__img {
    width: 100%;
    height: 350px;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      height: 200px;
    }
  }
}

// ---------------------------------
// concierge-heading  ← 以下削除予定 by nakajima
// ---------------------------------

.Sec-concierge-heading {
  margin: 40px 0;
}

.concierge-heading {
  &__txt {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.04rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
    }

    &--size_md {
      font-size: 3.6rem;

      @include media-breakpoint-down(sm) {
        font-size: 2.2rem;
      }
    }

    &--size_lg {
      font-weight: 1000;
      font-size: 5rem;

      @include media-breakpoint-down(sm) {
        font-size: 3.4rem;
      }
    }

    &-attention {
      color: $bright-green-900;

      &--size_md {
        font-size: 3.6rem;

        @include media-breakpoint-down(sm) {
          font-size: 2.2rem;
        }
      }

      &--size_lg {
        font-weight: 1000;
        font-size: 5rem;

        @include media-breakpoint-down(sm) {
          font-size: 3.4rem;
        }
      }
    }
  }
}

// ---------------------------------
// concierge-info
// ---------------------------------

.concierge-info {
  margin-bottom: 5rem;
}

.concierge-info-heading {
  display: flex;
  margin-bottom: 3rem;
  vertical-align: baseline;
  color: $white;
  background-color: $bright-green-900;

  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
  }

  &__num {
    margin: 0 16px;
    line-height: 2;
    font-size: 3rem;
    font-weight: bold;
    vertical-align: baseline;

    @include media-breakpoint-down(sm) {
      font-size: 4rem;
    }
  }

  &__ttl {
    margin: 1rem;
    font-size: 3rem;
    font-weight: bold;

    @include media-breakpoint-down(sm) {
      font-size: 2.2rem;
    }
  }
}

.concierge-info-detail {
  &__ttl {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: 900;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
      font-size: 2rem;
    }

    &--type_green {
      color: $bright-green-900;
    }
  }

  &__lead {
    letter-spacing: 0.1rem;

    &-attention {
      color: $pink;
      font-weight: bold;
    }

    &--type_green {
      font-size: 1.8rem;
      font-weight: bold;
      color: $bright-green-900;
    }

    &--type_pink {
      color: $bright-pink-900;
      font-weight: bold;
    }
  }

  &__message {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 900;
    color: $bright-red-900;
  }

  &__img {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    &-wrapper {
      margin: 3rem 0;
      text-align: center;
    }
  }
}

.concierge-info-detail-lists {
  margin-bottom: 4rem;

  &__list {
    list-style: none;
    background-color: $grey-100;
    margin: 1rem 0;
    font-weight: bold;
  }
}

.concierge-info-detail-howto {
  &__wrapper {
    margin-bottom: 3rem;
  }

  &__img {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__lead {
    font-weight: bold;
  }

  &__bottom {
    text-align: right;

    &-txt {
      font-weight: bold;
    }
  }
}

.concierge-info-detail-link {
  float: none;

  &__txt {
    margin-bottom: 1.6rem;
    font-weight: bold;
    font-size: 1.8rem;
  }
}

.concierge-info-detail-overseas {
  display: flex;
  margin-bottom: 5rem;

  @include media-breakpoint-down(sm) {
    display: inline-block;
  }

  &__heading {
    width: 60%;
    margin-right: 4rem;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 3rem;
    }
  }

  &__imgs {
    width: 40%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.concierge-info-detail-comment {
  display: flex;
  margin: 4rem 0;

  @include media-breakpoint-down(sm) {
    display: inline-block;
  }

  &__leads {
    position: relative;
    display: inline-block;
    width: 70%;
    padding: 3rem;
    color: $grey-600;
    font-size: 1.6rem;
    font-weight: bold;
    background: $right-blue-900;
    border-radius: 15px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: -30px;
      margin-top: -14px;
      border: 14px solid transparent;
      border-left: 16px solid $right-blue-900;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -15px;
    }
  }

  &__imgs {
    width: 30%;
    margin-left: 3rem;

    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
    }
  }
}

.concierge-info-detail-merit {
  background-color: $bright-yellow-300;

  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
  }

  &__ttl {
    color: $bright-green-900;
    font-size: 2.4rem;
    font-weight: 900;
    margin-bottom: 2rem;

    @include media-breakpoint-down(sm) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  &__lists {
    &-list {
      margin: 0 0 1rem 4rem;
    }
  }
}

.concierge-info-detail-support {
  height: 230px;
  text-align: center;
  color: $body-color;
  background-color: $white;
  border: 1px solid $grey-100;

  &__img {
    margin: 3rem;
    text-align: center;
  }
}

.concierge-info-modal {
  max-width: 80%;

  &__inner {
    height: 800px;
  }

  &__imgs {
    margin: 8rem;
    text-align: center;

    &-img {
      max-height: 600px;
      text-align: center;
    }
  }

  &__footer {
    background-color: $grey-100;
    color: $white;

    &-btn {
      font-size: 1.8rem;
      background-color: $dark-green-100;
      color: $white;

      &:hover {
        color: $white;
        background-color: $dark-green-200;
      }
    }
  }
}

.concierge-info-detail-aibiflow {
  margin-bottom: 3rem;

  @include media-breakpoint-down(sm) {
    text-align: center;
    margin-bottom: 1rem;
  }

  &__ttl {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
  }

  &__heading {
    &-txt {
      padding: 0.5rem 0;
      font-size: 1.6rem;
      color: $white;
      text-align: center;
      background-color: $bright-red-900;
    }
  }

  &__lists {
    background-color: $bright-red-100;

    &-list {
      padding: 0 2rem;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }

  &__img {
    margin: 10rem 2rem;

    @include media-breakpoint-down(sm) {
      margin: 0;
      text-align: center;
      transform: rotate(90deg);
    }
  }
}
