// ========================================
// variables.scss
// ========================================

// ---------------------------------
// Fonts
// ---------------------------------
@font-face {
  font-family: "noto-sans-jp";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/noto-sans-jp/NotoSansJP-Thin.otf") format("opentype");
}

@font-face {
  font-family: "noto-sans-jp";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/noto-sans-jp/NotoSansJP-Light.otf") format("opentype");
}

@font-face {
  font-family: "noto-sans-jp";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/noto-sans-jp/NotoSansJP-Regular.otf") format("opentype");
}

@font-face {
  font-family: "noto-sans-jp";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/noto-sans-jp/NotoSansJP-Medium.otf") format("opentype");
}

@font-face {
  font-family: "noto-sans-jp";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/noto-sans-jp/NotoSansJP-Bold.otf") format("opentype");
}

@font-face {
  font-family: "noto-sans-jp";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/noto-sans-jp/NotoSansJP-Black.otf") format("opentype");
}

@font-face {
  font-family: "urw-din";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/urwdin/URWDIN-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/urwdin/URWDIN-Light.woff") format("woff");
}

@font-face {
  font-family: "urw-din";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/urwdin/URWDIN-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/urwdin/URWDIN-Regular.woff") format("woff");
}

@font-face {
  font-family: "urw-din";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/urwdin/URWDIN-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/urwdin/URWDIN-Medium.woff") format("woff");
}

@font-face {
  font-family: "urw-din";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/urwdin/URWDIN-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/urwdin/URWDIN-Bold.woff") format("woff");
}

@font-face {
  font-family: "urw-din-cond";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/urwdin/URWDINCond-Demi.eot?#iefix") format("embedded-opentype"), url("../fonts/urwdin/URWDINCond-Demi.woff") format("woff");
}

@font-face {
  font-family: "urw-din-cond";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/urwdin/URWDINCond-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/urwdin/URWDINCond-Bold.woff") format("woff");
}

// [note] $font-family-base = $font-family-sans-serif
$font-family-sans-serif: "noto-sans-jp", "urw-din", Yu Gothic, meiryo, sans-serif, Yu Gothic;
$font-family-condensed-sans-serif: "urw-din-cond", "noto-sans-jp", Yu Gothic, meiryo, sans-serif;
$font-family-serif: Times, Georgia, serif;
$font-family-monospace: monospace;
$font-family-base: $font-family-sans-serif;
$font-family-base-cond: $font-family-condensed-sans-serif;
$font-family-display: $font-family-sans-serif;
$headings-font-family: $font-family-sans-serif;


// ---------------------------------
// Font
// ---------------------------------
$font-size-base: 1.6rem;
$font-weight-base: 400;
$font-size-xl: ($font-size-base * 1.5); // 24px
$font-size-lg: ($font-size-base * 1.25);  // 20px
$font-size-md: ($font-size-base * 1.125); // 18px
$font-size-sm: ($font-size-base * 0.875); // 14px
$font-size-xs: ($font-size-base) * 0.75;  // 12px


// ---------------------------------
// Heading Font
// ---------------------------------
$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px


// ---------------------------------
// Color System: Define Function
// ---------------------------------
$colors: ();

@function colors($key) {
  @return map-get($colors, $key);
}

$theme-colors: ();

@function theme-colors($key) {
  @return map-get($theme-colors, $key);
}

@function get-scale-color($color-info, $scaleLength, $percent) {
  $r: map-get($color-info, r);
  $g: map-get($color-info, g);
  $b: map-get($color-info, b);
  $ratio: $scaleLength - ($percent / 10);
  $result-r: $r + ((255 - $r) / $scaleLength) * $ratio;
  $result-g: $g + ((255 - $g) / $scaleLength) * $ratio;
  $result-b: $b + ((255 - $b) / $scaleLength) * $ratio;

  @return rgb($result-r, $result-g, $result-b);
}

// ---------------------------------
// Color System: Define Colors
// ---------------------------------
$white: #fff;
$black: #000;
$color-white: (hex: #fff, r: 255, g: 255, b: 255);
$color-black: (hex: #111, r: 17, g: 17, b: 17);
$color-gray: (hex: #656565, r: 101, g: 101, b: 101);
$color-living-coral: (hex: #ff6f61, r: 255, g: 111, b: 97);
$color-aqua-sky: (hex: #7ac5c5, r: 122, g: 197, b: 197);
$color-mimosa: (hex: #f0bf59, r: 240, g: 191, b: 89);
$color-turtle-green: (hex: #81894e, r: 129, g: 137, b: 78);
$color-ultra-violet: (hex: #5f4b8b, r: 95, g: 75, b: 139);
$color-red: (hex: #f00, r: 255, g: 0, b: 0);
$color-blue: (hex: #165e83, r: 22, g: 94, b: 131);
$color-dark-cyan: (hex: #008b8b, r: 0, g: 139, b: 139);
$color-yellow-green: (hex: #8dc952, r: 141, g: 201, b: 82);
$color-bright-green: (hex: #01a0ad, r: 1, g: 160, b: 173);
$color-banana-yellow: (hex: #f8e784, r: 248, g: 231, b: 132);
$color-lemon-yellow: (hex: #fcffdd, r: 252, g: 255, b: 221);
$color-choco-brown: (hex: #695a0b, r: 105, g: 90, b: 11);

$colors: map-merge(
  (
    "white": #fff,
    "black": map-get($color-black, hex),
    "black-900": get-scale-color($color-black, 10, 90),
    "black-800": get-scale-color($color-black, 10, 80),
    "black-700": get-scale-color($color-black, 10, 70),
    "black-600": get-scale-color($color-black, 10, 60),
    "black-500": get-scale-color($color-black, 10, 50),
    "black-400": get-scale-color($color-black, 10, 40),
    "black-300": get-scale-color($color-black, 10, 30),
    "black-200": get-scale-color($color-black, 10, 20),
    "black-100": get-scale-color($color-black, 10, 10),
    "gray": map-get($color-gray, hex),
    "gray-900": get-scale-color($color-gray, 10, 90),
    "gray-800": get-scale-color($color-gray, 10, 80),
    "gray-700": get-scale-color($color-gray, 10, 70),
    "gray-600": get-scale-color($color-gray, 10, 60),
    "gray-500": get-scale-color($color-gray, 10, 50),
    "gray-400": get-scale-color($color-gray, 10, 40),
    "gray-300": get-scale-color($color-gray, 10, 30),
    "gray-200": get-scale-color($color-gray, 10, 20),
    "gray-100": get-scale-color($color-gray, 10, 10),
    "banana-yellow": map-get($color-banana-yellow, hex),
    "banana-yellow-900": get-scale-color($color-banana-yellow, 10, 90),
    "banana-yellow-800": get-scale-color($color-banana-yellow, 10, 80),
    "banana-yellow-700": get-scale-color($color-banana-yellow, 10, 70),
    "banana-yellow-600": get-scale-color($color-banana-yellow, 10, 60),
    "banana-yellow-500": get-scale-color($color-banana-yellow, 10, 50),
    "banana-yellow-400": get-scale-color($color-banana-yellow, 10, 40),
    "banana-yellow-300": get-scale-color($color-banana-yellow, 10, 30),
    "banana-yellow-200": get-scale-color($color-banana-yellow, 10, 20),
    "banana-yellow-100": get-scale-color($color-banana-yellow, 10, 10),
    "lemon-yellow": map-get($color-lemon-yellow, hex),
    "lemon-yellow-900": get-scale-color($color-lemon-yellow, 10, 90),
    "lemon-yellow-800": get-scale-color($color-lemon-yellow, 10, 80),
    "lemon-yellow-700": get-scale-color($color-lemon-yellow, 10, 70),
    "lemon-yellow-600": get-scale-color($color-lemon-yellow, 10, 60),
    "lemon-yellow-500": get-scale-color($color-lemon-yellow, 10, 50),
    "lemon-yellow-400": get-scale-color($color-lemon-yellow, 10, 40),
    "lemon-yellow-300": get-scale-color($color-lemon-yellow, 10, 30),
    "lemon-yellow-200": get-scale-color($color-lemon-yellow, 10, 20),
    "lemon-yellow-100": get-scale-color($color-lemon-yellow, 10, 10),
    "living-coral": map-get($color-living-coral, hex),
    "living-coral-900": get-scale-color($color-living-coral, 10, 90),
    "living-coral-800": get-scale-color($color-living-coral, 10, 80),
    "living-coral-700": get-scale-color($color-living-coral, 10, 70),
    "living-coral-600": get-scale-color($color-living-coral, 10, 60),
    "living-coral-500": get-scale-color($color-living-coral, 10, 50),
    "living-coral-400": get-scale-color($color-living-coral, 10, 40),
    "living-coral-300": get-scale-color($color-living-coral, 10, 30),
    "living-coral-200": get-scale-color($color-living-coral, 10, 20),
    "living-coral-100": get-scale-color($color-living-coral, 10, 10),
    "aqua-sky": map-get($color-aqua-sky, hex),
    "aqua-sky-900": get-scale-color($color-aqua-sky, 10, 90),
    "aqua-sky-800": get-scale-color($color-aqua-sky, 10, 80),
    "aqua-sky-700": get-scale-color($color-aqua-sky, 10, 70),
    "aqua-sky-600": get-scale-color($color-aqua-sky, 10, 60),
    "aqua-sky-500": get-scale-color($color-aqua-sky, 10, 50),
    "aqua-sky-400": get-scale-color($color-aqua-sky, 10, 40),
    "aqua-sky-300": get-scale-color($color-aqua-sky, 10, 30),
    "aqua-sky-200": get-scale-color($color-aqua-sky, 10, 20),
    "aqua-sky-100": get-scale-color($color-aqua-sky, 10, 10),
    "mimosa": map-get($color-mimosa, hex),
    "mimosa-900": get-scale-color($color-mimosa, 10, 90),
    "mimosa-800": get-scale-color($color-mimosa, 10, 80),
    "mimosa-700": get-scale-color($color-mimosa, 10, 70),
    "mimosa-600": get-scale-color($color-mimosa, 10, 60),
    "mimosa-500": get-scale-color($color-mimosa, 10, 50),
    "mimosa-400": get-scale-color($color-mimosa, 10, 40),
    "mimosa-300": get-scale-color($color-mimosa, 10, 30),
    "mimosa-200": get-scale-color($color-mimosa, 10, 20),
    "mimosa-100": get-scale-color($color-mimosa, 10, 10),
    "turtle-green": map-get($color-turtle-green, hex),
    "turtle-green-900": get-scale-color($color-turtle-green, 10, 90),
    "turtle-green-800": get-scale-color($color-turtle-green, 10, 80),
    "turtle-green-700": get-scale-color($color-turtle-green, 10, 70),
    "turtle-green-600": get-scale-color($color-turtle-green, 10, 60),
    "turtle-green-500": get-scale-color($color-turtle-green, 10, 50),
    "turtle-green-400": get-scale-color($color-turtle-green, 10, 40),
    "turtle-green-300": get-scale-color($color-turtle-green, 10, 30),
    "turtle-green-200": get-scale-color($color-turtle-green, 10, 20),
    "turtle-green-100": get-scale-color($color-turtle-green, 10, 10),
    "bright-green": map-get($color-bright-green, hex),
    "bright-green-900": get-scale-color($color-bright-green, 10, 90),
    "bright-green-800": get-scale-color($color-bright-green, 10, 80),
    "bright-green-700": get-scale-color($color-bright-green, 10, 70),
    "bright-green-600": get-scale-color($color-bright-green, 10, 60),
    "bright-green-500": get-scale-color($color-bright-green, 10, 50),
    "bright-green-400": get-scale-color($color-bright-green, 10, 40),
    "bright-green-300": get-scale-color($color-bright-green, 10, 30),
    "bright-green-200": get-scale-color($color-bright-green, 10, 20),
    "bright-green-100": get-scale-color($color-bright-green, 10, 10),
    "ultra-violet": map-get($color-ultra-violet, hex),
    "ultra-violet-900": get-scale-color($color-ultra-violet, 10, 90),
    "ultra-violet-800": get-scale-color($color-ultra-violet, 10, 80),
    "ultra-violet-700": get-scale-color($color-ultra-violet, 10, 70),
    "ultra-violet-600": get-scale-color($color-ultra-violet, 10, 60),
    "ultra-violet-500": get-scale-color($color-ultra-violet, 10, 50),
    "ultra-violet-400": get-scale-color($color-ultra-violet, 10, 40),
    "ultra-violet-300": get-scale-color($color-ultra-violet, 10, 30),
    "ultra-violet-200": get-scale-color($color-ultra-violet, 10, 20),
    "ultra-violet-100": get-scale-color($color-ultra-violet, 10, 10),
    "red": map-get($color-red, hex),
    "red-900": get-scale-color($color-red, 10, 90),
    "red-800": get-scale-color($color-red, 10, 80),
    "red-700": get-scale-color($color-red, 10, 70),
    "red-600": get-scale-color($color-red, 10, 60),
    "red-500": get-scale-color($color-red, 10, 50),
    "red-400": get-scale-color($color-red, 10, 40),
    "red-300": get-scale-color($color-red, 10, 30),
    "red-200": get-scale-color($color-red, 10, 20),
    "red-100": get-scale-color($color-red, 10, 10),
    "blue": map-get($color-blue, hex),
    "blue-900": get-scale-color($color-blue, 10, 90),
    "blue-800": get-scale-color($color-blue, 10, 80),
    "blue-700": get-scale-color($color-blue, 10, 70),
    "blue-600": get-scale-color($color-blue, 10, 60),
    "blue-500": get-scale-color($color-blue, 10, 50),
    "blue-400": get-scale-color($color-blue, 10, 40),
    "blue-300": get-scale-color($color-blue, 10, 30),
    "blue-200": get-scale-color($color-blue, 10, 20),
    "blue-100": get-scale-color($color-blue, 10, 10),
    "dark-cyan": map-get($color-dark-cyan, hex),
    "dark-cyan-900": get-scale-color($color-dark-cyan, 10, 90),
    "dark-cyan-800": get-scale-color($color-dark-cyan, 10, 80),
    "dark-cyan-700": get-scale-color($color-dark-cyan, 10, 70),
    "dark-cyan-600": get-scale-color($color-dark-cyan, 10, 60),
    "dark-cyan-500": get-scale-color($color-dark-cyan, 10, 50),
    "dark-cyan-400": get-scale-color($color-dark-cyan, 10, 40),
    "dark-cyan-300": get-scale-color($color-dark-cyan, 10, 30),
    "dark-cyan-200": get-scale-color($color-dark-cyan, 10, 20),
    "dark-cyan-100": get-scale-color($color-dark-cyan, 10, 10),
    "yellow-green": map-get($color-yellow-green, hex),
    "yellow-green-900": get-scale-color($color-yellow-green, 10, 90),
    "yellow-green-800": get-scale-color($color-yellow-green, 10, 80),
    "yellow-green-700": get-scale-color($color-yellow-green, 10, 70),
    "yellow-green-600": get-scale-color($color-yellow-green, 10, 60),
    "yellow-green-500": get-scale-color($color-yellow-green, 10, 50),
    "yellow-green-400": get-scale-color($color-yellow-green, 10, 40),
    "yellow-green-300": get-scale-color($color-yellow-green, 10, 30),
    "yellow-green-200": get-scale-color($color-yellow-green, 10, 20),
    "yellow-green-100": get-scale-color($color-yellow-green, 10, 10),
    "choco-brown": map-get($color-choco-brown, hex),
    "choco-brown-900": get-scale-color($color-choco-brown, 10, 90),
    "choco-brown-800": get-scale-color($color-choco-brown, 10, 80),
    "choco-brown-700": get-scale-color($color-choco-brown, 10, 70),
    "choco-brown-600": get-scale-color($color-choco-brown, 10, 60),
    "choco-brown-500": get-scale-color($color-choco-brown, 10, 50),
    "choco-brown-400": get-scale-color($color-choco-brown, 10, 40),
    "choco-brown-300": get-scale-color($color-choco-brown, 10, 30),
    "choco-brown-200": get-scale-color($color-choco-brown, 10, 20),
    "choco-brown-100": get-scale-color($color-choco-brown, 10, 10),
    "alpha-black-10": rgba(map-get($color-black, hex), 0.1),
    "alpha-black-20": rgba(map-get($color-black, hex), 0.2),
    "alpha-black-30": rgba(map-get($color-black, hex), 0.3),
    "alpha-black-40": rgba(map-get($color-black, hex), 0.4),
    "alpha-black-50": rgba(map-get($color-black, hex), 0.5),
    "alpha-black-60": rgba(map-get($color-black, hex), 0.6),
    "alpha-black-70": rgba(map-get($color-black, hex), 0.7),
    "alpha-black-80": rgba(map-get($color-black, hex), 0.8),
    "alpha-black-90": rgba(map-get($color-black, hex), 0.9),
    "alpha-white-10": rgba(map-get($color-white, hex), 0.1),
    "alpha-white-20": rgba(map-get($color-white, hex), 0.2),
    "alpha-white-30": rgba(map-get($color-white, hex), 0.3),
    "alpha-white-40": rgba(map-get($color-white, hex), 0.4),
    "alpha-white-50": rgba(map-get($color-white, hex), 0.5),
    "alpha-white-60": rgba(map-get($color-white, hex), 0.6),
    "alpha-white-70": rgba(map-get($color-white, hex), 0.7),
    "alpha-white-80": rgba(map-get($color-white, hex), 0.8),
    "alpha-white-90": rgba(map-get($color-white, hex), 0.9),
  ),
  $colors
);


// ---------------------------------
// Color System: Define Theme Colors
// ---------------------------------
// [note]
// theme-colors() is special function
// You can use auto utilitiy classes like this.
// .text-primary, .bg-secondary

// Bootstrap
// ------------------------------
$primary: colors("blue");
$secondary: colors("turtle-green");
$success: colors("mimosa");
$info: colors("ultra-violet");
$warning: colors("mimosa");
$danger: colors("red-900");
$light: colors("gray-200");
$dark: colors("black-800");
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);


// Brand theme
// ------------------------------
$color-dropbox: #1f8ce6;
$color-facebook: #3b5998;
$color-feedly: #6cc655;
$color-flicker: #ff0084;
$color-google-plus: #dd4b39;
$color-instagram: #e1306c;
$color-line: #1dcd00;
$color-linkedin: #cd201f;
$color-pinterest: #cb2027;
$color-tumblr: #2c4762;
$color-twitter: #55acee;
$color-youtube: #cd201f;
$theme-colors: map-merge(
  (
    "dropbox": $color-dropbox,
    "facebook": $color-facebook,
    "feedly": $color-feedly,
    "flicker": $color-flicker,
    "google-plus": $color-google-plus,
    "instagram": $color-instagram,
    "line": $color-line,
    "linkedin": $color-linkedin,
    "pinterest": $color-pinterest,
    "tumblr": $color-tumblr,
    "twitter": $color-twitter,
    "youtube": $color-youtube
  ),
  $theme-colors
);


// ---------------------------------
// Element Style
// ---------------------------------

// Element - Base
// ---------------------------------
$body-bg: colors("white");
$body-color: colors("color-black");
$body-rvs-bg: $body-color;
$body-rvs-color: $body-bg;
$link-color: colors("blue");
$link-hover-color: darken($link-color, 15%);
$link-decoration: underline;
$link-hover-decoration: underline;
$color-border-cmn: colors("white");

// Element - header
// ---------------------------------
$header-bg-color: colors("black");
$header-color: colors("white");
$header-link-color: colors("white");
$header-link-nvr-color: colors("white");
$header-height: 70px;

// Element - footer
// ---------------------------------
$footer-bg-color: colors("black");
$footer-color: colors("white");
$footer-link-color: colors("white");
$footer-link-nvr-color: colors("white");


// Element - top
// ---------------------------------
$top-service-bg: colors("white");
$top-service-ttl: colors("black");
$top-works-bg: colors("black");
$top-works-ttl: colors("white");
$top-worksmap-bg: colors("white");
$top-worksmap-ttl: colors("black");

// ---------------------------------
// Grid columns / Spacers
// ---------------------------------
$spacer: 1rem; // Default: 1rem
$grid-gutter-width: 30px; // Default: 30px
$grid-gutter-half-width: $grid-gutter-width / 2;
$spacer-g: $grid-gutter-width;// Alias
$spacer-hg: $grid-gutter-half-width;// Alias
$spacer-cmn: $grid-gutter-half-width;// Alias

$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * 0.25),
    2: ($spacer * 0.5),
    3: $spacer,
    4: ($spacer * 2),
    5: ($spacer * 3),
    6: ($spacer * 4),
    7: ($spacer * 5.5),
    8: ($spacer * 7),
    9: ($spacer * 9),
    1px: 1px,
    5px: 5px,
    10px: 10px,
    15px: 15px,
    20px: 20px,
    25px: 25px,
    30px: 30px,
    35px: 35px,
    g: $grid-gutter-width,
    hg: $grid-gutter-half-width,
    cmn: $spacer-cmn
  ),
  $spacers
);


// ---------------------------------
// Sizes
// ---------------------------------
// $sizes
// ex) .w-90 = width: 90%;
// ------------------------------
$sizes: () !default;
$sizes: map-merge(
  (
    // ↓[note] Already defined
    // 25: 25%,
    // 50: 50%,
    // 75: 75%,
    // 100: 100%,
    // auto: auto
    // ↑[note] Already defined
    90: 90%,
    80: 80%,
    70: 70%,
    60: 60%,
    40: 40%,
    30: 30%,
    900px: 900px,
    800px: 800px,
    700px: 700px,
    600px: 600px,
    500px: 500px,
    400px: 400px,
    300px: 300px
  ),
  $sizes
);


// ---------------------------------
// Grid breakpoints
// ---------------------------------
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);


// ---------------------------------
// Grid containers
// ---------------------------------
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);
