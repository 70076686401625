// ---------------------------------
// Card
// ---------------------------------

// ---------------------------------
// cmn-card-item
// ---------------------------------

.cmn-card-item {
  height: 100%;
}

.cmn-card-item-imgs {
  &__img {
    object-fit: cover;
    width: 100%;

    &:hover {
      opacity: 0.4;
      transition: 0.2s;
    }
  }
}

.cmn-card-item-body {
  height: 180px;

  &__ttls {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &-category {
      background-color: $grey-500;
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      width: 25%;
      padding: 10px;
      margin: 0;
    }

    &-ttl {
      word-break: break-all;
      width: 75%;
      margin: 0 0 0 10px;

      &-link {
        font-size: 1.6rem;
        font-weight: bold;
        overflow: hidden;
      }
    }
  }

  &__leads {
    &-lead {
      color: $black;
      font-size: 1.4rem;
      overflow: hidden;
    }
  }
}

// ---------------------------------
// CardProduct 以下、削除予定です by nakajima
// ---------------------------------
.CardProduct {
  &__img {
    width: 100%;
    object-fit: cover;

    &:hover {
      opacity: 0.4;
      transition: 0.2s;
    }
  }

  &__CardSlide {
    height: 320px;
    background-color: $white;

    @include media-breakpoint-down(xs) {
      height: 400px;
    }
  }
}

// ---------------------------------
// InfoProduct
// ---------------------------------
.InfoProduct {
  &__type {
    &--web {
      padding: 1rem;
      background-color: $grey-500;
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
    }

    &--app {
      padding: 1rem;
      background-color: $grey-500;
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
    }

    &--ai {
      padding: 1rem;
      background-color: $grey-500;
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
    }
  }

  &__link {
    padding: 0;
  }

  &__name {
    margin-left: 1rem;
    text-align: left;
    font-size: 1.6rem;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  &__txt {
    text-align: left;
    font-size: 1.4rem;
    font-weight: bold;
    color: $black;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
}
