// ========================================
// company
// ========================================

// ---------------------------------
// Company
// ---------------------------------

.Company {
  background-color: $bright-green-100;
  padding-bottom: 5rem;
}

// ---------------------------------
// company-mv
// ---------------------------------

.company-mv {
  position: relative;
  height: 598px;
  background: {
    image: url(/official-app/assets/images/company/bg-pc-message.jpg);
    color: $white;
    attachment: fixed;
    size: cover;
    repeat: no-repeat;
    position: center;
  }
}

.company-mv-heading {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 20px;

  &__inner {
    display: flex;
    align-items: center;
    padding: 0 30px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 0 15px;
    }
  }
}

.company-mv-heading-ttls {
  color: $white;
  width: 60%;

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-bottom: 20px;
  }

  &__ttl {
    font-size: 8rem;
    font-weight: 800;
    line-height: 1;
    text-shadow: $gray-800 1px 2px 6px;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      font-size: 4.5rem;
    }

    &-attention {
      color: $bright-green-900;
      text-shadow: $gray-800 1px 2px 6px;
    }
  }

  &__lead {
    font-size: 2rem;
    font-weight: 800;
    text-shadow: $black 1px 2px 6px;
  }
}

.company-mv-heading-imgs {
  width: 40%;

  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: 500px;
  }

  &__inner {
    width: 100%;
    display: flex;
  }

  &__img {
    width: 33%;
    height: 100%;
  }
}

// ---------------------------------
// company-info
// ---------------------------------

.company-info {
  color: $bright-brown-900;
  margin-bottom: 30px;
}

.company-info-message {
  &__ttl {
    font-size: 2.4rem;
    font-weight: 900;
    margin-bottom: 20px;
  }

  &__lead {
    font-weight: bold;
  }
}

.company-info-profile-face {
  text-align: center;

  &__img {
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }
}

.company-info-profile-detail {
  &__name {
    font-size: 2.8rem;
    font-weight: bold;
  }

  &__summary {
    font-weight: bold;
    margin-bottom: 30px;
  }

  &__lead {
    font-size: 1.4rem;
    line-height: 1.8;
    padding: 3rem;

    &-wrapper {
      background-color: $white;
      opacity: 0.9;
    }
  }
}

// ---------------------------------
// company-detail
// ---------------------------------

.company-detail-table {
  width: 100%;
}

.company-detail-table-row {
  &__header {
    background-color: $gray-200;
    border: 1px solid $gray-300;
    padding: 0.5rem 1rem;
    width: 30%;
  }

  &__data {
    background-color: $white;
    border: 1px solid $gray-300;
    font-size: 1.4rem;
    width: 70%;
    padding: 0.5rem 1rem;

    &-lists {
      list-style: none;
    }

    &-list {
      margin: 0.5rem 0;
    }
  }
}
