// ========================================
// _ikeda-mixins.scss
// ========================================
// ---------------------------------
// ikeda-variables
// ---------------------------------

// ---------------------------------
// padding
// ---------------------------------
$padding-y__pc-wrap: 80px;
$padding-y__tb-wrap: 15px;
$padding-xy__tb-wrap: 40px 15px;
$padding-x__tb-wrap: 15px;

// ---------------------------------
// margin
// ---------------------------------
$marginbottom__pc-txt: 40px;

// ---------------------------------
// z-index
// ---------------------------------
$zindex-fixed: 900000;
$zindex-concept-wave: 30;
$zindex-concept-ttl: 30;
$zindex-concept-txt: 30;
$zindex-concept: 20;
$zindex-mvlogo: 20;
$zindex-header: 10;


$effectMove: 60;
$effectTime: 0.4;
// ---------------------------------
// bglist
// ---------------------------------
$bglist: a,b,c,d;

@each $bg in $bglist {
  .ImgHeading__#{$bg} {
    background-image: url(../bg/bg_#{$bg}.png);
  }
}

// ---------------------------------
// text-decolation
// ---------------------------------

// title-space
// ------------------------------
@mixin Cmn-Title-textstyle {
  margin-bottom: $marginbottom__pc-txt;//このファイル上部で指定しています
  text-align: center;
}

@mixin Top-Title-textstyle {
  font: {
    size: 6rem;
    weight: bold;
  }

  margin-bottom: 1rem;
}

// ---------------------------------
// Color-controll
// ---------------------------------

// Card
// ------------------------------
@mixin Card-Type-Web {
  text-align: center;
  color: $white;
  background-color: colors("gray-800");
}

@mixin Card-Type-App {
  text-align: center;
  color: $white;
  background-color: colors("black-800");
}
