// .cta-area
// ---------------------------
.cta-area {
  $_theme-color: #02a0ad;

  padding: 30px;
  background-color: rgba(2, 160, 173, 0.05);
  border: 1px solid $_theme-color;
  text-align: center;

  & > *:last-child {
    margin-bottom: 0;
  }

  &__title {
    padding-bottom: 10px;
    margin-top: -6px;
    margin-bottom: 40px;
    border-bottom: 1px solid currentColor;
    color: $_theme-color;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
