// ========================================
// effect
// ========================================
// ---------------------------------
//  Typing
// ---------------------------------
@keyframes typing { from { width: 0; } }

@keyframes caret { 50% { border-color: transparent; } }

.Typing-1 {
  width: 80ch;
  border-right: 0.1rem solid;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3s steps(60, end), caret 1s step-end infinite;
}

.Typing-2 {
  width: 60ch;
  border-right: 0.1rem solid;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3s steps(60, end), caret 1s step-end infinite;
}

// ---------------------------------
//  Fade in out
// ---------------------------------
@keyframes Fade-down {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.Fade-down-1 {
  animation-duration: 4s;/* アニメーション時間 */
  animation-name: Fade-down;/* アニメーション名 */
  animation-iteration-count: 1;
}
