// ---------------------------------
// .site-bottom-info-bar
// ---------------------------------

.site-bottom-info-bar {
  background-color: #02a0ad;
  bottom: 0;
  position: fixed;
  z-index: 998;
  width: 100%;
  padding: 2rem 1rem;
  opacity: 0.9;

  &__lead {
    color: $white;
    margin: 0 10rem 0 0;
  }

  &__btn {
    background-color: $white;
    border-radius: 3rem;
    color: $black;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    text-decoration: none;
    margin-right: 1rem;
    padding: 0.4rem 1rem;

    &:hover {
      background-color: #555;
      color: $white;
      text-decoration: none;
    }
  }
}
