// ========================================
// page
// ========================================

// ---------------------------------
// Global
// ---------------------------------
*:focus { outline: none; }

html,
body,
div,
span,
ul {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  position: relative;
}

html {
  font-size: 10px;
  height: 100%;
  overflow-x: hidden;
}

body {
  background: $body-bg;
  color: $body-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  overflow-wrap: break-word;
  overflow-x: hidden;
  padding: 0;
  text-size-adjust: 100%;
  word-wrap: break-word;

  @include media-breakpoint-down(xs) {
    letter-spacing: 0.04rem;
  }
}

a {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: darken($link-color, 15%);
    text-decoration: $link-hover-decoration;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

table {
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
}


// ---------------------------------
// Main container
// ---------------------------------
// .page {}


// ---------------------------------
// Bootstrap containfers
// ---------------------------------

// .container / .container-fluid
// ------------------------------
.container,
.container-fluid {
  // *** Adjust inner elements gutter
  &--gutter_small-gutter {
    @include col-space();
  }

  &--gutter_small-gutter-for-sm {
    @include media-breakpoint-down(sm) {
      @include col-space();
    }
  }
}

// .sec
// ------------------------------
// .sec {}

// .row
// ------------------------------
// .row {}

// .col-xx
// ------------------------------
// .col-xx {}
