// ========================================
// contact
// ========================================

.Sec-contact-form {
  min-height: 400px;
}

// ---------------------------------
// contact-form
// ---------------------------------

.contact-form {
  &__lead {
    margin-bottom: 30px;
  }
}

.contact-form-cnt {
  &__term {
    margin-bottom: 5px;

    &-attention {
      color: $red;
      margin-left: 10px;
    }
  }

  &__description {
    width: 100%;
    margin-bottom: 30px;

    &-input {
      border: 1px $gray-400 solid;
      width: 50%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      &--type_lg {
        width: 100%;
        height: 200px;
      }
    }
  }
}

.contact-form-cnt-btns {
  text-align: center;

  &__btn {
    display: inline-block;

    &-txt {
      background-color: $bright-green-900;
      border-radius: 8px;
      border: none;
      color: $white;
      transition: all 0.3s;
      width: 250px;
      padding: 10px 0;

      @include media-breakpoint-down(sm) {
        width: 150px;
      }

      &:hover {
        background-color: $dark-green-300;
      }

      &--type_back {
        background-color: #b6babc;

        &:hover {
          background-color: #686a6b;
        }
      }
    }
  }
}
