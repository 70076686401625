// ========================================
// pastwork
// ========================================

// ---------------------------------
// works-result
// ---------------------------------

.works-result {
  height: 100%;
  padding: 0 5px;
}

// ---------------------------------
// pastwork
// ---------------------------------
.Pastwork {
  &__PastworkHeading {
    margin: 5rem 0;

    &__heading {
      text-align: left;
    }
  }

  &__SearchBox {
    margin-bottom: 5rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 2rem;
    }
  }

  &__SearchResult {
    margin-bottom: 5rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 2rem;
    }
  }
}

// ---------------------------------
// SearchBox
// ---------------------------------
.SearchBox {
  &__NarrowMenu {
    display: flex;
    width: 100%;
  }

  &__type {
    display: inline-block;
    width: 100%;
    height: 8rem;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    border: 1px solid $grey-500;

    @include media-breakpoint-down(sm) {
      height: 6rem;
      font-size: 1.2rem;
    }

    &.is-active {
      color: $black;
      background-color: $bright-yellow-900;
    }
  }
}

// ---------------------------------
// NarrowMenu
// ---------------------------------
.NarrowMenu {
  &__Btn {
    margin: 1%;
    width: 23%;
  }
}

.is-hide {
  display: none;
}

// ---------------------------------
// SearchResult
// ---------------------------------
.SearchResult {
  &__CardProduct {
    margin-bottom: 2rem;
  }
}

// ---------------------------------
// NarrowResult
// ---------------------------------
.NarrowResult {
  &__CardProduct {
    padding: 0 5px;
    margin-bottom: 2rem;
    list-style: none;
  }
}
