// ========================================
// color-variables.scss
// ========================================

// [note] override bootstrap color system and extension it.

// ---------------------------------
// color system - color definition
// ---------------------------------

// mono ※ override
// ------------------------------
$white: #fff;
$black: #000;

// grey
// ------------------------------
$grey-100: #eaf0f2;
$grey-200: #d0d5d7;
$grey-300: #b6babc;
$grey-400: #9ca0a1;
$grey-500: #828586;
$grey-600: #686a6b;
$grey-700: #4e5050;
$grey-800: #343535;
$grey-900: #1a1a1a;

// yellow-green
// ------------------------------
$yellow-green-100: #f2f9eb;//header-drop-bg
$yellow-green-200: #e5f3d8;
$yellow-green-300: #d9edc5;
$yellow-green-400: #cce7b2;
$yellow-green-500: #bfe19e;
$yellow-green-600: #b3db8b;
$yellow-green-700: #a6d578;
$yellow-green-800: #99cf65;
$yellow-green-900: #8dc952;//header-hover

// bright-yellow
// ------------------------------
$bright-yellow-100: #fefcf1;
$bright-yellow-200: #fdf9e3;
$bright-yellow-300: #fcf7d6;
$bright-yellow-400: #fbf4c8;
$bright-yellow-500: #fbf1ba;
$bright-yellow-600: #faefad;
$bright-yellow-700: #f9ec9f;
$bright-yellow-800: #f8e991;
$bright-yellow-900: #f8e784;

// bright-brown
// ------------------------------
$bright-brown-100: #eeece3;
$bright-brown-200: #dddac8;
$bright-brown-300: #cdc8ad;
$bright-brown-400: #bcb592;
$bright-brown-500: #aba377;
$bright-brown-600: #9b915c;
$bright-brown-700: #8a7e41;
$bright-brown-800: #796c26;
$bright-brown-900: #695a0b;

// bright-green
// ------------------------------
$bright-green-100: #e2f4f5;
$bright-green-200: #c6e9ec;
$bright-green-300: #aadfe3;
$bright-green-400: #8ed4da;
$bright-green-500: #72cad1;
$bright-green-600: #56bfc8;
$bright-green-700: #3ab5bf;
$bright-green-800: #1eaab6;
$bright-green-900: #02a0ad;//title text color


// dark-green
// ------------------------------
$dark-green-100: #02a0ad;
$dark-green-200: #018e99;
$dark-green-300: #017c86;
$dark-green-400: #016a73;
$dark-green-500: #015860;
$dark-green-600: #00474c;
$dark-green-700: #003539;
$dark-green-800: #002326;
$dark-green-900: #001113;

// bright-red
// ------------------------------
$bright-red-100: #fbebed;
$bright-red-200: #f8d8db;
$bright-red-300: #f5c5c9;
$bright-red-400: #f2b2b7;
$bright-red-500: #ef9ea5;
$bright-red-600: #ec8b93;
$bright-red-700: #e97881;
$bright-red-800: #e6656f;
$bright-red-900: #e3525d;

// bright-pink
// ------------------------------
$bright-pink-100: #f9e7ed;
$bright-pink-200: #f3cfdb;
$bright-pink-300: #edb7ca;
$bright-pink-400: #e79fb8;
$bright-pink-500: #e287a6;
$bright-pink-600: #dc6f95;
$bright-pink-700: #d65783;
$bright-pink-800: #d03f71;
$bright-pink-900: #cb2760;//attention-txt


// right blue
// ------------------------------
$right-blue-100: #f8fcfc;
$right-blue-200: #f2fafa;
$right-blue-300: #ecf7f8;
$right-blue-400: #e5f5f6;
$right-blue-500: #dff2f4;
$right-blue-600: #d9f0f2;
$right-blue-700: #d2edf0;
$right-blue-800: #ccebee;
$right-blue-900: #c6e9ec;


// For Japan map
// ------------------------------

$color-map-hover: $grey-700;
$color-map-disabled: #ecf7f8;
$color-map-sp: $bright-green-400;
$color-hokkaido: #8bd0f7;
$color-tohoku: #57c1ea;
$color-kanto: #bfe060;
$color-chubu: #ffda7e;
$color-hokuriku: #ffda7e;
$color-kansai: #f798ed;
$color-shikoku: #f798ed;
$color-chugoku: #f8769e;
$color-kyushu: #fa7;
$color-okinawa: #fa7;
