// ========================================
// parallax-view-with-btn.scss
// ========================================

// ---------------------------------
// service-info-parallax
// ---------------------------------

.service-info-parallax {
  margin: 5rem 0;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 270px;

    background: {
      image: url(/official-app/assets/images/service/rainbowbridge.jpg);
      attachment: fixed;
      size: cover;
      repeat: no-repeat;
      position: center;
    }
  }

  &__link {
    @include btn-cmn;

    background-color: colors('banana-yellow');
    box-shadow: $black 1px 1px 10px;
    color: colors('choco-brown');
    display: block;
    width: 80%;
    padding: 2rem 0;

    &:hover {
      background-color: $white;
      color: colors('bright-green');
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
      width: 90%;
    }
  }
}

// ---------------------------------
// concierge-info-parallax ← 以下削除予定 by nakajima
// ---------------------------------

.concierge-info-parallax {
  margin: 5rem 0;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 270px;

    background: {
      image: url(/official-app/assets/images/service/rainbowbridge.jpg);
      attachment: fixed;
      size: cover;
      repeat: no-repeat;
      position: center;
    }
  }

  &__link {
    @include btn-cmn;

    background-color: colors('banana-yellow');
    box-shadow: $black 1px 1px 10px;
    color: colors('choco-brown');
    display: block;
    width: 80%;
    padding: 2rem 0;

    &:hover {
      background-color: $white;
      color: colors('bright-green');
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
      width: 90%;
    }
  }
}
