// ========================================
// top
// ========================================

// ---------------------------------
// top-mv
// ---------------------------------

.top-mv__inner {
  clear: both;
  text-align: center;
  margin: auto;
}

.top-mv-txts {
  position: absolute;
  z-index: $zindex-mvlogo;
  padding: 400px 60px 0;

  @include media-breakpoint-down(lg) {
    padding: 300px 15px 0;
  }

  @include media-breakpoint-down(md) {
    padding: 200px 15px 0;
  }

  &__img {
    display: block;
    width: 100%;
    max-width: 400px;
    margin-bottom: 30px;
  }

  &__lead {
    color: $white;
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    text-shadow: $black 1px 2px 6px;
    word-wrap: break-word;

    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
    }
  }
}

.top-mv-swiper {
  &__img {
    object-fit: cover;
    width: 100%;

    @include media-breakpoint-down(md) {
      height: 600px;
    }
  }
}

// ---------------------------------
// top-concept
// ---------------------------------

.Sec-top-concept {
  background-color: $black;
  padding: 80px 0;
}

.top-concept {
  color: $white;
}

.top-concept-heading {
  @include Cmn-Title-textstyle;

  &__ttl {
    @include Top-Title-textstyle;
  }

  &__subttl {
    font-size: 4rem;
    font-weight: bold;
  }
}

.top-concept-wave {
  background-image: url(/official-app/assets/images/top/bg-concept.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);
  width: 100%;
  padding: 0;

  &::after {
    content: '';
    background: linear-gradient(to bottom, rgba($black, 1), rgba(#def, 0) 80%, rgba($white, 0.5));
    display: block;
    left: 0;
    top: 0;
    transform: translate3d(0, 0, 0);
    z-index: 11;
    width: 100%;
    height: 100%;
  }

  &__block {
    animation: drift 3000ms infinite linear;
    background: $red;
    border-radius: 43%;
    opacity: 0.4;
    position: absolute;
    top: 3%;
    left: 50%;
    transform-origin: 50% 48%;
    z-index: $zindex-concept-wave;
    width: 500px;
    height: 500px;
    margin-left: -250px;
    margin-top: -250px;
  }

  &__parts-1 {
    animation: drift 7000ms infinite linear;
    background: $white;
    opacity: 0.1;
  }

  &__parts-2 {
    animation: drift 5000ms infinite linear;
  }

  &__ttl {
    color: $white;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.4em;
    line-height: 300px;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    transform: translate3d(0, 0, 0);
    text-shadow: 0 1px 0 rgba($black, 0.1);
    text-indent: 0.3em;
    z-index: $zindex-concept-ttl;
    width: 100%;
  }

  &__txts {
    &-lead {
      color: $white;
      font-weight: bold;
      line-height: 2.4;
      position: absolute;
      top: 50%;
      text-align: center;
      z-index: $zindex-concept-txt;
      width: 100%;
      padding: 0 15px;
      margin-bottom: 8px;
    }

    &-img {
      opacity: 0.9;
      object-fit: cover;
      width: 50%;
      height: 600px;
    }
  }
}

@keyframes drift {
  from {
    transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
  }
}

// ---------------------------------
// top-service
// ---------------------------------

.Sec-top-service {
  background-color: $top-service-bg;
  padding: 80px 0;
}

.top-service {
  color: $top-service-ttl;
}

.top-service-heading {
  @include Cmn-Title-textstyle;

  &__ttl {
    @include Top-Title-textstyle;
  }

  &__lead {
    font-weight: bold;
  }
}

.top-service-items {
  @include media-breakpoint-down(md) {
    padding: 0 30px;
  }
}

.top-service-item {
  background-color: $white;
  box-shadow: colors('gray-400') 2px 2px 8px;
  color: $body-color;
  text-align: center;
  height: auto;

  &:hover {
    box-shadow: colors('gray-600') 4px 4px 16px;
    transition: all 0.4s;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 15px;
  }

  &__inner {
    color: $black;

    @include media-breakpoint-down(lg) {
      height: auto; //dummy
    }
  }

  &__img {
    width: 60px;
    height: auto;
    margin: 2rem 0;
  }

  // &__ttl {}

  &__lead {
    text-align: left;
    font-size: 1.4rem;
    margin: 2rem 1rem;
  }

  &__btn {
    margin-bottom: 2rem;
  }
}

// ---------------------------------
// top-works
// ---------------------------------

.Sec-top-works {
  background-color: $black;
  padding: 80px 0;
}

.top-works {
  color: $top-works-ttl;
}

.top-works-heading {
  @include Cmn-Title-textstyle;

  &__ttl {
    @include Top-Title-textstyle;
  }

  &__lead {
    font-weight: bold;
  }
}

.top-works-items {
  background-color: $top-works-bg;
  margin-bottom: 50px;

  &__inner {
    height: auto;
  }
}

.top-works-item {
  background-color: $white;
}

.top-works-links {
  &__inner {
    display: flex;

    @include media-breakpoint-down(md) {
      display: block;
      text-align: center;
    }
  }

  &__btn {
    margin: 8px;
  }
}

// ---------------------------------
// top-worksmap
// ---------------------------------

.Sec-top-worksmap {
  background-color: $top-worksmap-bg;
  padding: 80px 0;
}

.top-worksmap {
  color: $top-worksmap-ttl;
}

.top-worksmap-heading {
  @include Cmn-Title-textstyle;

  &__ttl {
    @include Top-Title-textstyle;
  }

  &__lead {
    font-weight: bold;
  }
}

.top-worksmap-japan {
  max-width: 1000px;
  padding-right: 2%;
  margin: auto;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  &__line {
    display: flex;
    margin-left: auto;

    @include media-breakpoint-down(md) {
      display: inline;
    }

    &--row_1 {
      width: 14%;
    }

    &--row_2 {
      width: 14%;
    }

    &--row_3 {
      width: 14%;
    }

    &--row_4 {
      width: 14%;
    }

    &--row_5 {
      width: 28%;
    }

    &--row_6 {
      width: 77%;
    }

    &--row_7 {
      width: 77%;
    }

    &--row_8 {
      width: 98%;
    }

    &--row_9 {
      width: 42%;
      margin-left: 2%;
    }

    &--row_10 {
      width: 21%;
      margin-left: 2%;
    }

    &--row_11 {
      width: 7%;
      margin-left: 2%;
    }
  }

  &__link {
    border-radius: 0.8rem;
    color: $black;
    display: block;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    width: 100%;
    margin: 0.5rem;

    &:hover {
      background-color: $color-map-hover;
      color: $white;
      text-decoration: none;
    }

    @include media-breakpoint-down(md) {
      background-color: $color-map-sp !important;
      float: left;
      width: 23%;
      padding: 1rem 0;
      margin: 0 1% 1.5rem;

      &:hover {
        color: $black;
      }
    }

    @include media-breakpoint-down(xs) {
      margin: 0 1% 0.5rem;
    }

    &--state_disabled {
      background-color: $color-map-disabled !important;
      cursor: not-allowed;

      &:hover {
        color: $black;
      }
    }

    &--layout_long {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      margin-bottom: -5rem;

      @include media-breakpoint-down(md) {
        display: block;
        align-items: initial;
        justify-content: initial;
        z-index: initial;
        margin: 0 1%;
      }
    }

    &--type_spacer {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &--type_hokkaido {
      background-color: $color-hokkaido;
    }

    &--type_tohoku {
      background-color: $color-tohoku;
    }

    &--type_kanto {
      background-color: $color-kanto;
    }

    &--type_hokuriku {
      background-color: $color-hokuriku;
    }

    &--type_chubu {
      background-color: $color-chubu;
    }

    &--type_kansai {
      background-color: $color-kansai;
    }

    &--type_chugoku {
      background-color: $color-chugoku;
    }

    &--type_shikoku {
      background-color: $color-shikoku;
    }

    &--type_kyushu {
      background-color: $color-kyushu;
    }

    &--type_okinawa {
      background-color: $color-okinawa;
    }
  }
}

// ---------------------------------
// top-mapmodal
// ---------------------------------

.top-mapmodal {
  max-width: 1000px;
  margin: 150px auto;

  @include media-breakpoint-down(md) {
    margin: 150px 15px;
  }

  &__inner {
    display: block;
  }
}

.top-mapmodal-heading {
  display: block;
  text-align: center;
  padding: 20px 15px;

  &__ttl {
    font-size: 2rem;
  }
}

.top-mapmodal-items-wrapper {
  margin: 46px;
}

.top-mapmodal-items {
  padding: 20px 15px;
}

.top-mapmodal-item {
  margin-bottom: 15px;
}

.top-mapmodal-footer {
  padding: 20px 15px;

  &__btn {
    background-color: $bright-green-900;
    color: $white;
    font-size: 1.6rem;
    font-weight: bold;

    &:hover {
      background-color: $dark-green-200;
      color: $white;
    }
  }
}
